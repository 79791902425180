(function () {
    'use strict';

    // Global Modules

    function HttpReq(url, contentType, input) {
        this.method = input ? 'POST' : 'GET';
        this.data = input ? input : {};
        this.contentType = contentType;
        this.url = url;
    }
    HttpReq.prototype.then = function (resolve, reject) {
        var req = new XMLHttpRequest();
        req.onreadystatechange = function () {
            if (req.readyState == XMLHttpRequest.DONE) {
                if (req.status == 200) { resolve(req.response); }
                else { reject(req.statusText); }
            }
        };
        req.open(this.method, this.url, true);
        req.setRequestHeader('Content-Type', this.contentType);
        req.send(this.data);
    };

    NodeList.prototype.addEventListener = function (type, f, uc) { this.forEach(function (x) { x.addEventListener(type, f, uc); }); };

    Element.prototype.setAttributes = function (attrs) {
        var el = this;
        Object.keys(attrs).forEach(function (key) {
            if (key === 'style' && typeof attrs[key] === 'object') {
                Object.keys(attrs[key]).forEach(function (prop) {
                    el.style[prop] = attrs[key][prop];
                });
            } else if (key === 'html') el.innerHTML = attrs[key];
            else el.setAttribute(key, attrs[key]);
        });
        return el;
    };
    

    // Carousel

    function Carousel(config) {
        this.config = config;
        this.config.selector.style.overflow = 'hidden';
        this.wrapper = config.selector.querySelector('div');

        this.wrapper.setAttributes({ style: { position: 'relative', left: '0px', transition: 'left ' + this.config.duration + 'ms ease-out 0s' } });
        this.wrapper.dataset.select = 0;
        if (config.control) {
            config.selector.querySelector('.next').addEventListener('click', this.next.bind(this));
            config.selector.querySelector('.prev').addEventListener('click', this.prev.bind(this));
        }
        window.addEventListener('resize', this.init.bind(this), false);
        this.items = Array.prototype.slice.call(this.wrapper.children);
        this.init();
    }
    Carousel.prototype.init = function () {
        var perPage = 1;
        if (typeof this.config.perPage === 'object') {
            var pp = this.config.perPage;
            Object.keys(pp).forEach(function (viewport) {
                if (window.innerWidth >= viewport) { perPage = pp[viewport]; return; }
            });
        }
        var sw = 0;
        var i = 4;
        var widthItem = 0;
        while (--i >= 0 && sw !== this.config.selector.offsetWidth) {
            sw = this.config.selector.offsetWidth;
            widthItem = sw / perPage;
            this.items.forEach(function (widthItem,x) {
                x.style.float = 'right';
                x.style.width = widthItem + 'px';
            }.bind(this, widthItem));
            this.wrapper.style.width = widthItem * this.items.length + 'px';
        }
        this.wrapper.dataset.perPage = perPage;
        this.slide(0);
        if (this.config.oninit) this.config.oninit(this);
    };
    Carousel.prototype.slide = function (inc, e) {
        var sel = parseInt(this.wrapper.dataset.select) + inc;
        var count = this.items.length;
        var perpage = parseInt(this.wrapper.dataset.perPage);
        var maxsel = count>perpage?count - perpage:0;
        if (sel < 0) { sel = this.config.loop ? maxsel : 0; }
        if (sel > maxsel) { sel = this.config.loop ? 0 : maxsel; }
        var selectorWidth = this.config.selector.offsetWidth;
        var widthItem = selectorWidth / perpage;
        this.wrapper.style.left = (sel * widthItem) + 'px';
        if (this.config.onSlide) this.config.onSlide(sel, this);
        this.wrapper.dataset.select = sel;
        if (this.config.control) {
            this.config.selector.querySelector('.next').style.display = sel < maxsel ? 'block' : 'none';
            this.config.selector.querySelector('.prev').style.display = sel > 0 ? 'block' : 'none';
        }
    };
    Carousel.prototype.next = function (e) { this.slide(+1, e); };
    Carousel.prototype.prev = function (e) { this.slide(-1, e); };

    // create template
    function makeTemplate(products, element, tmplel) {
        var template = document.querySelector(tmplel);
        element.innerHTML = '';
        products.forEach(function(p){
            var Card = document.importNode(template.content, true);
            ['href', 'title', 'alt', 'src', 'textContent','className'].forEach(function (atr) {
                Card.querySelectorAll("[data-" + atr.toLowerCase() + "]").forEach(function (x) {
                    x[atr] = p[x.dataset[atr.toLowerCase()]];
                    x.removeAttribute("data-" + atr.toLowerCase());
                });
            });
            element.appendChild(Card);
        });
    }

    // choice category
    function ChoiceLinks(selector, api, cb) {
        document.querySelectorAll(selector + '>a').addEventListener('click', function () {
            document.querySelectorAll(selector + '>a.active').forEach(function (x) { x.classList.remove("active"); });
            this.classList.add("active");
            new HttpReq('/api/products/' + api + '/' + this.dataset.itemid, 'text/html; charset=utf-8').then(
                function (res) {
                    cb(JSON.parse(res));
                }, function (status) {
                    console.log({ status: 'خطا', erroeText: status });
                });
        });
    }


    // main

    window.addEventListener('DOMContentLoaded', function () {
        var slider_el = document.querySelector('#slider');
        var slider = new Carousel({
            selector: slider_el, loop: true, duration: 1000
            //,
            //onSlide: function (next, slider) {
            //    var curr = parseInt(slider.wrapper.dataset.select);
            //    var tran = slider.config.selector.querySelector('#slider-transition');
            //    if (next !== curr && next !== 0) {
            //        var nextel = slider.items[next];
            //        tran.querySelector('img').src = nextel.querySelector('img').src;
            //        tran.classList.add('active');
            //        setTimeout(function () { tran.classList.remove('active'); }, 1000);
            //        nextel.querySelectorAll('.b-animation').forEach(function (x) {
            //            x.classList.add('activeB');
            //            setTimeout(function () { x.classList.remove('activeB'); }, 1000);
            //        });
            //    }
            //}
        });
        setInterval(slider.next.bind(slider), slider_el.dataset.delay);

        var productsSlider =new Carousel({ selector: document.querySelector('#carousel'), duration: 300, control: true, perPage: { 0: 2, 576: 3, 768: 4, 992: 5 } });
        ChoiceLinks('#categoryLinks', 'category', function (res) {
            makeTemplate(res, productsSlider.wrapper, '#products-template');
            productsSlider.wrapper.left = '0px';
            productsSlider.wrapper.dataset.select = 0;
            productsSlider.items = Array.prototype.slice.call(productsSlider.wrapper.children);
            productsSlider.init();
        });

        ChoiceLinks('#tagLinks', 'tags', function (res) {
            makeTemplate(res, document.querySelector('#tagpro'), '#products-tags-template');
        });


        new Carousel({
            selector: document.querySelector('#Brands'),
            duration: 300,control: true,
            perPage: { 0: 2, 576: 3, 768: 4, 992: 5 },
            oninit: function (slider) {
                var maxHeight = slider.items.reduce(function (a, x) { return Math.max(x.offsetHeight, a); }, 0);
                slider.items.forEach(function (x) { x.style.minHeight = maxHeight + 'px'; });
            }
        });


    }, false);
}());
